<script setup>
import { ref } from 'vue';
import AppMenuItem from './AppMenuItem.vue';
const model = ref([
    // {
    //     label: 'Home',
    //     items: [{ label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/dashboard' }]
    // },
    {
        label: 'Members',
        items: [
            {
                label: 'Members',
                icon: 'pi pi-fw pi-id-card',
                to: '/members'
            },
        ]
    },
    {
        label: 'Features',
        items: [
            {
                label: 'Rebate Categories',
                icon: 'pi pi-fw pi-sitemap',
                to: '/mlm/rebate-categories',
            },
            {
                label: 'Rebate Products',
                icon: 'pi pi-fw pi-book',
                to: '/mlm/products',
            },
            // {
            //     label: 'Members',
            //     icon: 'pi pi-fw pi-users',
            //     to: '/mlm/members',
            // },
            {
                label: 'Orders',
                icon: 'pi pi-fw pi-ticket',
                to: '/mlm/orders',
            },
            {
                label: 'Rebate History',
                icon: 'pi pi-fw pi-wallet',
                to: '/mlm/user-rebate-history'
            },
            {
                label: 'Member Loyalty History',
                icon: 'pi pi-fw pi-wallet',
                to: '/user-loyalty'
            },
            {
                label: 'Member Vouchers',
                icon: 'pi pi-fw pi-wallet',
                to: '/user-vouchers'
            },
        ]
    },
]);
</script>

<template>
    <ul class="layout-menu">
        <template v-for="(item, i) in model" :key="item">
            <app-menu-item v-if="!item.separator" :item="item" :index="i"></app-menu-item>
            <li v-if="item.separator" class="menu-separator"></li>
        </template>
        <li>
            <a href="https://www.primefaces.org/primeblocks-vue/#/" target="_blank">
                <!-- <img src="/layout/images/banner-primeblocks.png" alt="Prime Blocks" class="w-full mt-3" /> -->
            </a>
        </li>
    </ul>
</template>

<style lang="scss" scoped></style>
